/*----- 20. Cart page  -----*/

.cart-main-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin: 0 0 15px;
}

.cart-table-content {
  table {
    border: 1px solid #ebebeb;

    thead>tr {
      border: 1px solid #ebebeb;
      background-color: #f9f9f9;

      th {
        font-size: 14px;
        font-weight: 500;

        padding: 21px 45px 22px;

        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        text-transform: uppercase;

        color: #333;
        border-top: medium none;

        @media #{$lg-layout} {
          padding: 21px 35px 22px;
        }

        @media #{$xs-layout} {
          padding: 21px 20px 22px;
        }
      }
    }

    tbody>tr {
      border-bottom: 1px solid #ebebeb;

      td.product-thumbnail,
      .td-product-thumbnail {
        width: 150px;

        img {
          max-height: 95.67px;

          @media #{$xs-layout} {
            max-height: 38.67px;
          }

          @media #{$md-layout} {
            max-height: 88.67px;
          }
        }
      }

      td.product-name,
      .td-product-name {
        width: 435px;

        text-align: left;

        a {
          font-size: 15px;
          font-weight: 500;

          color: #333;
        }
      }

      td.product-price-cart,
      .td-product-price-cart {
        width: 435px;

        span {
          font-weight: 500;

          color: #333;

          &.old {
            margin-right: 10px;

            text-decoration: line-through;

            color: #8e8e8e;
          }
        }
      }

      td.product-subtotal,
      .td-product-subtotal {
        font-weight: 500;

        color: #333;
      }

      td.product-quantity,
      .td-product-quantity {
        width: 435px;

        .cart-plus-minus {
          position: relative;

          display: inline-block;

          width: 110px;
          height: 40px;
          padding: 0;

          .qtybutton {
            font-size: 16px;

            position: absolute;

            float: inherit;

            width: 20px;
            margin: 0;

            cursor: pointer;
            transition: all 0.3s ease 0s;
            text-align: center;

            color: #333;
            border: none;
            background: none;
          }

          .dec.qtybutton {
            top: 0;
            left: 0;

            height: 40px;

            border-right: 1px solid #e5e5e5;

            &:disabled {
              cursor: not-allowed;
            }
          }


          .inc.qtybutton {
            top: 0;
            right: 0;

            height: 40px;

            border-left: 1px solid #e5e5e5;

            &:disabled {
              cursor: not-allowed;
            }
          }

          input.cart-plus-minus-box {
            font-size: 14px;

            float: left;

            width: 110px;
            height: 40px;
            margin: 0;
            padding: 0;

            text-align: center;

            color: #333;
            border: 1px solid #e1e1e1;
            background: transparent none repeat scroll 0 0;
          }
        }
      }

      td.product-remove,
      .td-product-remove {
        width: 100px;

        a,
        button {
          font-size: 17px;

          margin: 0 13px;

          color: #666;
          border: none;
          background: none;
        }
      }

      td.product-wishlist-cart>a,
      td.product-wishlist-cart>button {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;

        display: block;

        margin: 0 auto;
        padding: 10px 15px;

        text-transform: uppercase;

        color: #fff;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: $theme-color;

        &:hover,
        &.active {
          background-color: #333;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      td {
        font-size: 15px;

        padding: 30px 0 30px 30px;

        text-align: center;

        color: #333;

        @media (max-width: 1200px) {
          width: calc(100% - 30px);
        }
      }
    }
  }
}

/* Default styles for the table */
.cart-table {
  width: 100%;
}

/* Media query for screens smaller than 1200px */
@media (max-width: 1200px) {
  .cart-table-content table {
    border: none;
  }

  .th-n-td {
    display: flex;
    //background-color: pink;
    width: 100%;
    align-items: center;
  }

  .cart-table {
    display: block;
  }

  .cart-table thead {
    display: none;
  }

  .cart-table tbody {
    display: block;
  }

  .cart-table td {
    width: 100%;
  }

  .cart-table tr {
    margin-bottom: 20px;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #ebebeb;
    position: relative;
  }

  .cart-table td.product-name,
  .cart-table .td-product-name,
  .cart-table td.product-thumbnail,
  .cart-table .td-product-thumbnail,
  .cart-table td.product-price-cart,
  .cart-table .td-product-price-cart,
  .cart-table td.product-quantity,
  .cart-table .td-product-quantity,
  .cart-table td.product-subtotal,
  .cart-table .td-product-subtotal,
  .cart-table td.product-remove,
  .cart-table .td-product-remove {
    width: 70% !important;
    padding: 8px 8px !important;
    white-space: normal;
    text-align: center !important;
    //background-color: rgb(0, 179, 255);
    white-space: normal;
    overflow-wrap: break-word;
  }

  .cart-table-th,
  .cart-table th {
    //background-color: yellow;
    width: 30%;
    padding: 8px 0;
    text-align: center;
    white-space: normal;
    overflow-wrap: break-word;
    vertical-align: middle;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cart-table-td {
    width: 70%;
  }

  .cart-table-th {
    font-weight: bold;
  }

  .product-count {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $theme-color;
    color: white;
    padding: 0px 5px 5px 10px;
    border-radius: 0px 0px 0px 100%;
  }
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;

  padding: 30px 0 55px;

  @media #{$xs-layout} {
    display: block;

    padding: 30px 0 15px;
  }

  .cart-shiping-update>a,
  .cart-clear>button,
  .cart-clear>a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    display: inline-block;

    padding: 17px 63px 17px;

    text-transform: uppercase;

    color: #363f4d;
    border-radius: 50px;
    background-color: #f2f2f2;

    @media #{$md-layout} {
      font-size: 13px;

      padding: 18px 25px 17px;
    }

    @media #{$xs-layout} {
      margin: 0 0 15px;
      padding: 18px 40px 17px;
      width: 100%;
      text-align: center;
    }

    &:hover {
      color: #fff;
      background-color: $theme-color;
    }
  }

  .cart-shiping-update>a {
    background-color: $theme-gray-2;
    color: white;

    &:hover {
      background-color: $hover-gray;
    }
  }

  .cart-clear>button,
  .cart-clear>a {
    background-color: #fc6969;
    color: #fff;

    &:hover {
      background-color: #db5b5b;
    }
  }

  .cart-clear>button {
    margin-right: 25px;

    cursor: pointer;
    transition: all 0.3s ease 0s;

    border: medium none;

    &:last-child {
      margin-right: 0;
    }

    @media #{$md-layout} {
      margin-right: 15px;
    }
  }
}

.cart-tax,
.discount-code-wrapper {
  padding: 45px 30px 50px;

  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f9f9f9;

  @media #{$lg-layout} {
    padding: 45px 18px 50px;
  }

  @media #{$xs-layout} {
    padding: 45px 18px 50px;
  }

  .title-wrap {
    position: relative;

    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: all 0.4s ease 0s;

      background-color: #e3e1e1;
    }

    h4.cart-bottom-title {
      font-size: 18px;
      font-weight: 500;

      display: inline-block;

      margin: 0;
      padding-right: 18px;

      @media #{$lg-layout} {
        font-size: 16px;
      }
    }

    .section-bg-gray {
      position: relative;
      z-index: 9;
      background-color: #f8f9f9;
    }
  }

  .tax-wrapper {
    margin-top: 22px;

    p {
      margin: 0;
    }

    .tax-select-wrapper {
      margin: 5px 0 0;

      .tax-select {
        margin: 0 0 26px;

        label {
          font-size: 14px;

          margin: 0 0 5px;

          color: #242424;
        }

        select {
          font-size: 12px;

          width: 100%;
          height: 40px;
          padding: 0 50px 0 15px;

          cursor: pointer;

          color: #242424;
          border: 1px solid #ebebeb;
          box-shadow: none;

          -webkit-appearance: none;
          -moz-appearance: none;
        }

        input {
          height: 40px;

          border: 1px solid #ebebeb;
          background: #fff none repeat scroll 0 0;
        }
      }

      button.cart-btn-2 {
        font-size: 14px;
        font-weight: 500;

        padding: 12px 42px 12px;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-transform: uppercase;

        color: #fff;
        border: medium none;
        border-radius: 50px;
        background-color: $theme-color;
      }
    }
  }

  .discount-code {
    margin: 21px 0 0;

    p {
      margin: 0 0 15px;
    }

    form {
      input {
        height: 40px;
        margin-bottom: 30px;
        padding-left: 10px;

        border: 1px solid #ebebeb;
        background: #fff;
      }

      button.cart-btn-2 {
        font-size: 14px;
        font-weight: 500;

        padding: 12px 42px 12px;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-transform: uppercase;

        color: #fff;
        border: medium none;
        border-radius: 50px;
        background-color: $theme-color;
      }

      .coupon-warn-message {
        margin-top: 15px;
        text-align: center;
        min-height: 48px;
      }
    }
  }
}

.grand-totall {
  padding: 45px 30px 50px;

  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f9f9f9;

  @media #{$lg-layout} {
    padding: 45px 18px 50px;
  }

  @media #{$md-layout} {
    margin-top: 30px;
  }

  .title-wrap {
    position: relative;
    margin-bottom: 36px;

    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: all 0.4s ease 0s;

      background-color: #e3e1e1;
    }

    h4.cart-bottom-title {
      font-size: 18px;
      font-weight: 500;

      display: inline-block;

      margin: 0;
      padding-right: 18px;
    }

    .section-bg-gary-cart {
      position: relative;
      z-index: 9;

      background-color: #f9f9f9;
    }
  }

  h5 {
    font-size: 18px;

    span {
      font-size: 18px;
      font-weight: 500;
      float: right;
    }
  }

  h5.exception {
    span {

      .iNvQzL,
      .bReATY,
      .btpBnd {
        width: 54px;
      }

      .iNvQzL div,
      .bReATY div,
      .btpBnd div {
        background: black !important;
      }
    }
  }

  .mwst-text {
    margin-bottom: 25px;
  }

  .postage-h5 {
    font-size: 18px;
    margin-bottom: 25px;

    span {
      &.coupon {
        background-color: lightgray;
        font-size: 12px;
        float: none;
        vertical-align: middle;
        padding: 0 5px;
        border-radius: 5px;
      }

      .iNvQzL,
      .bReATY,
      .btpBnd {
        width: 54px;
      }

      .iNvQzL,
      .bReATY div,
      .btpBnd div {
        background: black !important;
      }
    }
  }

  .total-shipping {
    margin: 0 0 25px;
    padding: 28px 0;

    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;

    h5 {
      font-size: 14px;

      margin: 0;
    }

    ul {
      padding: 19px 0 0 0;

      li {
        margin: 0 0 6px;

        list-style: outside none none;

        color: #242424;

        &:last-child {
          margin: 0 0 0;
        }

        input {
          position: relative;
          top: 2px;

          width: 13px;
          height: 13px;
          margin-right: 10px;
          padding: 0;

          cursor: pointer;

          color: #626262;
          border: 1px solid #d7d7d7;
          border-radius: 5px !important;
          background: #e9e9e9 none repeat scroll 0 0;
        }

        span {
          float: right;
        }
      }
    }
  }

  h4.grand-totall-title {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 25px;

    color: $theme-color;

    span {
      float: right;

      .laWeCN,
      .iQTQrc,
      .gLQrop {
        width: 54px;
      }
    }
  }

  .grand-totall-login-wrapper {
    text-align: center;
    margin-top: 7px;
  }

  a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    display: block;

    padding: 18px 10px 17px;

    text-align: center;
    text-transform: uppercase;

    color: #fff;
    border-radius: 50px;
    background-color: $theme-color;

    &:hover {
      background-color: $theme-color-hover;
    }

    &.grand-totall-login {
      display: inline;
      padding: 0;
      color: $theme-color;
      background-color: transparent;
      margin-left: 5px;

      &:hover {
        color: $theme-color-hover;
        background-color: transparent;
      }
    }
  }
}

.divider-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0;
  line-height: 25px;
}

.divider-line-small {
  line-height: 1.4;
  margin: 5px 0 !important;
}

.line-sperator {
  flex: 1;
  height: 1px;
  background-color: #000;
}

.line-text {
  margin: 0 10px;
}

.discount-code-wrapper {
  @media #{$xs-layout} {
    margin: 30px 0;
  }
}

.cart-item-variation {
  margin-top: 10px;

  span {
    display: block;
  }
}

.tr-fulfilment-product {
  border-bottom: 0 !important;
}

.tr-fulfilment-product td {
  padding-bottom: 0 !important;
}

.tr-fulfilment-center {
  border-top: 0 !important;
}

.tr-fulfilment-center td {
  padding-top: 5px !important;
  text-align: left !important;
}

.select-fulfilment-center {
  background-color: transparent;
  border: 1px solid #e1e1e1;
  color: #333;
  font-size: 14px;
  height: 40px;
  padding: 0 15px;
  width: calc(100% - 16px);
}

.td-fulfilment-center label {
  color: $theme-color;
  font-size: 14px;
  font-weight: 500;
  margin: 0;

  @media (max-width: 1200px) {
    padding: 10px 0 0;
    line-height: 1.3;
    max-width: 90%;
  }

  @media #{$xs-layout} {
    font-size: 13px;
  }
}