.floating-whatsapp-button {
    width: 48px !important;
    height: 48px !important;
    right: 20px !important;
    bottom: 229px !important;

    @media screen and (max-width: 800px) {
        bottom: 68px !important;
    }
}

.floating-whatsapp-button::after {
    animation: none !important;
}

.floating-whatsapp-chatbox {
    right: 78px !important;
    bottom: 229px !important;

    img {
        background-color: white;
        object-fit: contain;
    }

    @media screen and (max-width: 575px) {
        right: 0 !important;
    }


    @media screen and (max-width: 800px) {
        bottom: 68px !important;
    }
}